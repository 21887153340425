import React, { useState, useEffect } from "react"
import { IClientAction, Response, ActionStatusEnum } from "../../../../types"
import CSPortalService, { ActivityQueryParams } from "../../data-sources/csRestApi"
import { Button } from "@blueprintjs/core"
import ActionListComponent from "./actionList.layout"

export enum userTypeEnum {
  "ADMIN",
  "AGENT",
  "READER",
  "NONE",
}

type ActionListProps = Omit<ActivityQueryParams, "page"> & {
  listType: userTypeEnum
}

export const ActionList = ({ type, status, listType }: ActionListProps) => {
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [pendingFilter, setPendingFilter] = useState(false)

  const typedInitArray: IClientAction[] = []
  const [actions, setActions] = useState(typedInitArray)

  const styles = {
    button: {
      marginLeft: "15px",
      marginBottom: "15px",
      fontWeight: "bold" as "bold",
      fontFamily: "Raleway",
    },
  }

  const getActions = async () => {
    const queryParams: ActivityQueryParams = {
      ...(type && { type }),
      ...(status && { status }),
      ...(page && { page }),
    }

    if (pendingFilter) {
      queryParams.status = ActionStatusEnum.PENDING
    }

    const activities = await CSPortalService.getActivity(queryParams)
    return activities
  }

  const getPaginationButtons = () => {
    const buttons: JSX.Element[] = []
    for (let i = 0; i < pageCount; i++) {
      buttons.push(
        <Button style={styles.button} key={i} onClick={() => setPage(i + 1)}>
          {i + 1}
        </Button>,
      )
    }
    return buttons
  }

  useEffect(() => {
    getActions().then((data: Response["actionsActivity"]) => {
      setPageCount(data.totalPages)
      setActions(data.results)
    })
  }, [page, pendingFilter])

  return (
    <>
      <Button style={{ marginBottom: "12px" }} onClick={() => setPendingFilter(!pendingFilter)}>
        Show {pendingFilter ? "All" : "Only Pending"}
      </Button>
      <ActionListComponent listType={listType} actions={actions} paginationButtons={getPaginationButtons()} />
    </>
  )
}
