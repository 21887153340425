import React, { FunctionComponent } from "react"
import { Alert } from "@blueprintjs/core"

type IProps = {
  message: string
  isOpen: boolean
  openStateHandler(isOpenFlag: string): void
}

const AlertPopup: FunctionComponent<IProps> = ({ message, isOpen, openStateHandler }) => {
  return (
    <Alert isOpen={isOpen} onClose={() => openStateHandler("")} canEscapeKeyCancel={true}>
      {message}
    </Alert>
  )
}

export default AlertPopup
