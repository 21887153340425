import React, { FunctionComponent, useState } from "react"
import { Button, ButtonGroup } from "@blueprintjs/core"
import SuccessAdminPopover from "../AcceptAdminPopover/acceptAdminPopover"
import DeclineAdminPopover from "../DeclineAdminPopover/declineAdminPopover"

// This is for the base component which lives in this file
// The popovers require the state variables while this component does not
type BaseAdminPopoverProps = {
  actionId: string
  type: string
  createdAt: string
  createdByName: string
}

export type AdminPopoverProps = {
  isOpen: boolean
  isOpenStateHandler(state: boolean): void
} & BaseAdminPopoverProps

const AdminDecisionPopover: FunctionComponent<BaseAdminPopoverProps> = ({
  actionId,
  type,
  createdAt,
  createdByName,
}) => {
  const styles = {
    buttons: {
      fontFamily: "Quicksand",
      fontSize: "24px",
    },
  }

  const [declineDialogOpen, setDeclineDialogOpen] = useState(false)
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)

  return (
    <>
      <ButtonGroup style={{ minWidth: 120 }}>
        <Button
          style={styles.buttons}
          intent="success"
          large={true}
          icon="thumbs-up"
          onClick={() => setAcceptDialogOpen(!acceptDialogOpen)}
        >
          Approve
        </Button>
        <Button
          style={styles.buttons}
          intent="danger"
          icon="cross"
          large={true}
          onClick={() => setDeclineDialogOpen(!declineDialogOpen)}
        >
          Decline
        </Button>
      </ButtonGroup>
      <SuccessAdminPopover
        actionId={actionId}
        type={type}
        createdAt={createdAt}
        createdByName={createdByName}
        isOpen={acceptDialogOpen}
        isOpenStateHandler={setAcceptDialogOpen}
      />
      <DeclineAdminPopover
        actionId={actionId}
        type={type}
        createdAt={createdAt}
        createdByName={createdByName}
        isOpen={declineDialogOpen}
        isOpenStateHandler={setDeclineDialogOpen}
      />
    </>
  )
}

export default AdminDecisionPopover
