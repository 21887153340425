import React, { useReducer } from "react"
import { FormGroup, InputGroup, Card, ButtonGroup, Button } from "@blueprintjs/core"
import { FormInputRequirements, FormInputField } from "../../../../types"
import { navigate } from "gatsby"

type FormInputProps = {
  formLabel: string
  actionName: string
  description: string
  approvalRequired: boolean
  fields: FormInputRequirements["fields"]
  inputCallback(formInput: FormFields, requiredFormInputKeys: FormInputField["requestInputKey"][]): Promise<void>
}

export type FormFields = {
  [key in FormInputField["requestInputKey"]]: string | number
}

// type here refers to reducer (switch-case below) function that is called
type AddInputReducerParams = {
  key: string
  value: string
  type: "ADD_INPUT"
}

const FormInputComponent = ({ inputCallback, formLabel, description, approvalRequired, fields }: FormInputProps) => {
  const card = { paddingBottom: "25px", marginBottom: "25%" }

  const initialState = {} as FormFields

  fields.forEach((item) => {
    initialState[item.requestInputKey] = ""
  })

  const reducer = (state: FormFields, action: AddInputReducerParams) => {
    switch (action.type) {
      case "ADD_INPUT":
        return {
          ...state,
          [action.key]: action.value,
        }
      default: {
        return state
      }
    }
  }

  const [formInputs, setFormInputs] = useReducer(reducer, initialState)

  const getJSXFormFields = (): JSX.Element[] => {
    return fields.map((item, key) => (
      <FormGroup key={key} helperText={item.inputDescription} label={item.label} labelFor={item.requestInputKey}>
        <InputGroup
          id={item.requestInputKey}
          onChange={(evt: any) =>
            setFormInputs({ key: item.requestInputKey, type: "ADD_INPUT", value: evt.target.value })
          }
          placeholder={item.label}
          type={item.type}
        />
      </FormGroup>
    ))
  }

  const requiredFormInputKeys = fields.filter((field) => field.required === true).map((field) => field.requestInputKey)
  return (
    <Card style={card}>
      <h1 style={{ textTransform: "capitalize" }}>{formLabel}</h1> <br />
      <p>{description}</p>
      {getJSXFormFields()}
      {approvalRequired ? <small> * This action requires approval from a CS Manager </small> : <></>}
      <ButtonGroup>
        <Button intent="success" onClick={() => inputCallback(formInputs, requiredFormInputKeys)}>
          Create Action Request
        </Button>
        <Button intent="warning" onClick={() => navigate("/app/")}>
          Cancel
        </Button>
      </ButtonGroup>
    </Card>
  )
}

export default FormInputComponent
