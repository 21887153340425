import React, { useEffect, FunctionComponent, useState } from "react"
import { Auth } from "aws-amplify"
import { RouteProps } from "../../utils/types"
import Layout from "../../shared/Layout/layout"
import { Callout } from "@blueprintjs/core"
import { navigate } from "gatsby"

const NoPermissionsPage: FunctionComponent<RouteProps> = () => {
  const styles = {
    warningTitle: {
      color: "#CF000F",
      fontFamily: "Raleway",
      paddingTop: "20px",
    },
    warningText: {
      color: "#CF000F",
      fontFamily: "Raleway",
      fontSize: "24px",
    },
  }

  const [hasGroup, setHasGroup] = useState(false)

  const checkAuthGroup = async (): Promise<boolean> => {
    const auth = await Auth.currentAuthenticatedUser()
    const isInGroup = auth.signInUserSession.idToken.payload["cognito:groups"].length <= 1 ? false : true
    return isInGroup
  }

  const redirectToSearch = (): void => {
    navigate("/app/users/search")
  }

  useEffect(() => {
    checkAuthGroup().then((groupCheck) => {
      setHasGroup(groupCheck)
    })
  }, [])

  return (
    <Layout>
      {!hasGroup ? (
        <Callout className="bp3-intent-warning">
          <h1 style={styles.warningTitle}> No Permissions</h1>
          <p style={styles.warningText}>
            Please contact your administator to add you to a group. If your admin has already added you to a group.
            Please logout and login again.
          </p>
        </Callout>
      ) : (
        <>{redirectToSearch()}</>
      )}
    </Layout>
  )
}

export default NoPermissionsPage
