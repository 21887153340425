export * from './responses'
export * from './requests'
export * from './requests'

import { RequestBody } from './requests'

// Typed JSON
// See: https://github.com/microsoft/TypeScript/issues/27930#issuecomment-430417209
export type JSON<T> = string & { __JSON__: T }
declare const JSON: {
  parse: <T>(str: JSON<T>) => T
  stringify: <T>(obj: T) => JSON<T>
}

export type ValueOf<T> = T[keyof T]

// https://stackoverflow.com/a/50375286/9513995
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

export enum UserGroupsEnum {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  READ_ONLY = 'READ_ONLY',
}

export enum ActionStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ActionTypeEnum {
  REBATE = 'rebate',
  DISABLE_USER = 'disable-user',
  CHANGE_CARD_NUMBER = 'change-card-number',
}

type RequestBodyKeys = keyof UnionToIntersection<ValueOf<RequestBody>>
export interface IUser {
  id: string
  group: UserGroupsEnum
  isActive: boolean
  firstName: string
  lastName: string
  picture: string
  email: string
  createdAt: string
  updatedAt: string
}

export interface IAction {
  id: string
  type: ActionTypeEnum
  createdBy: string | IUser
  createdAt: string
  confirmedBy: string | IUser
  confirmedAt: string
  adminNote: string
  status: ActionStatusEnum
  requestInput: ValueOf<RequestBody>
  meta: string
}

export interface IClientAction extends IAction {
  createdBy: IUser
  confirmedBy: IUser
}

export type FormInputField = {
  label: string
  requestInputKey: RequestBodyKeys
  type: 'text' | 'number' | 'checkbox'
  required: boolean
  component: 'textarea' | 'input'
  inputDescription: string
}

export type FormInputRequirements = {
  type: ActionTypeEnum
  formLabel: string
  formDescription: string
  approvalRequired: boolean
  fields: FormInputField[]
}
