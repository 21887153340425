import React from "react"
import AvatarImage from "../../images/avatar-blank.png"

type AvatarProps = {
  imageSrc?: string
}

const Avatar = ({ imageSrc }: AvatarProps) => {
  const styles = {
    image: {
      borderRadius: "500px",
      display: "block",
      width: "100%",
    },
  }

  return !imageSrc ? <img src={AvatarImage} style={styles.image} /> : <img src={imageSrc} style={styles.image} />
}

export default Avatar
