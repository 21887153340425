import React, { FunctionComponent } from "react"
import Layout from "../../shared/Layout/layout"
import { RouteProps } from "../../utils/types"

const RedashTransactionSearchPage: FunctionComponent<RouteProps> = () => {
  return (
    <Layout>
      <iframe width="100%" height="100%" src="https://app.redash.io/northone/dashboard/transaction-search"></iframe>
    </Layout>
  )
}

export default RedashTransactionSearchPage
