import React from "react"

type IProps = {
  width?: string
  height?: string
  redashPath: string
}

const RedashUserIFrame: React.FC<IProps> = ({ width, height, redashPath }) => (
  <iframe width={width ? width : "100%"} height={height ? height : "100%"} src={redashPath}></iframe>
)

export default RedashUserIFrame
