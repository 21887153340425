import React, { FunctionComponent } from "react"
import { Collapse, Button } from "@blueprintjs/core"
import { Container, Row, Col } from "react-grid-system"

type TogglerProps = {
  label: string
  open: boolean
  setOpen: React.Dispatch<boolean>
  isFluid: boolean
}

export const Toggler: FunctionComponent<TogglerProps> = ({ label, children, open, setOpen, isFluid }) => {
  const styles = {
    button: {
      marginLeft: "15px",
      marginBottom: "15px",
      fontWeight: "bold" as "bold",
      fontFamily: "Raleway",
    },
  }

  return (
    <Container fluid={isFluid}>
      <Row>
        <Col>
          <Button style={styles.button} onClick={() => setOpen(!open)}>
            {open ? "Hide" : "Show"} {label}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse isOpen={open}>{children}</Collapse>
        </Col>
      </Row>
    </Container>
  )
}
