import React, { FunctionComponent, useState, useEffect } from "react"
import { Auth } from "aws-amplify"

import Layout from "../../shared/Layout/layout"
import { RouteProps } from "../../utils/types"
import { ActionList } from "../../shared/ActionList"
import { Container, Col, Row } from "react-grid-system"
import { userTypeEnum } from "../../shared/ActionList/actionList.container"

const HomePage: FunctionComponent<RouteProps> = () => {
  const [group, setGroup] = useState<userTypeEnum>(userTypeEnum.NONE)
  const styles = {
    container: {
      marginTop: "25px",
    },
    title: {
      textAlign: "center" as "center",
      fontFamily: "Raleway",
    },
    subtitle: {
      textAlign: "center" as "center",
      fontFamily: "Quicksand",
    },
  }

  const getUserGroup = async () => {
    const auth = await Auth.currentAuthenticatedUser()

    const groups = auth.signInUserSession.idToken.payload["cognito:groups"]

    if (groups.includes("ADMIN")) {
      setGroup(userTypeEnum.ADMIN)
    } else if (groups.includes("AGENT")) {
      setGroup(userTypeEnum.AGENT)
    } else {
      setGroup(userTypeEnum.READER)
    }
  }

  useEffect(() => {
    getUserGroup()
      .then()
      .catch((e) => {
        console.log(`An error occured ${e}`)
      })
  }, [])

  return (
    <Layout>
      <Container fluid={true} style={styles.container}>
        <Row align="center">
          <Col md={12}>
            <h1 style={styles.title}> Activity </h1>
            <p style={styles.subtitle}>List of actions</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ActionList listType={group} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default HomePage
