import React, { useEffect, useState } from "react"
import FormInputComponent, { FormFields } from "./formInput.component"
import CSPortal from "../../data-sources/csRestApi"
import AlertPopup from "../AlertPopup/alertPopup"
import { ActionTypeEnum, FormInputRequirements, FormInputField } from "../../../../types"
import CSPortalService from "../../data-sources/csRestApi"
import { transformBaseRequestFromFormInput } from "../../utils/transformers"
import { validateBaseActionCreateRequestBody, ValidatorStatuses } from "../../utils/validation"

type FormInputProps = {
  actionName: ActionTypeEnum
}

export const FormInput = ({ actionName }: FormInputProps) => {
  const [alertMessage, setAlertMessage] = useState("")

  const [action, setAction] = useState<FormInputRequirements>({
    formLabel: "",
    formDescription: "",
    type: ActionTypeEnum.REBATE,
    fields: [],
    approvalRequired: false,
  })

  const [loading, setLoading] = useState(true)

  const getCurrentActionRequirements = async () => {
    const actionResponse = await CSPortal.getActionRequirements(actionName)
    return actionResponse
  }

  const updateActionStatus = async (
    formInput: FormFields,
    requiredFormInputKeys: FormInputField["requestInputKey"][],
  ): Promise<void> => {
    const inputData = transformBaseRequestFromFormInput(formInput)
    const isValid = validateBaseActionCreateRequestBody(formInput, requiredFormInputKeys)

    if (isValid.status === ValidatorStatuses.VALID) {
      const updatedAction = await CSPortalService.postActionInputs({ actionName, actionInput: inputData })
      if (updatedAction) {
        setAlertMessage("Action has been created successfully")
        if (typeof window !== "undefined") {
          window.location.reload()
        }
      } else {
        setAlertMessage("Action has not been created. Please check over your fields or try again later.")
      }
    } else {
      setAlertMessage(isValid.message)
    }
  }

  useEffect(() => {
    getCurrentActionRequirements().then((actionResponse) => {
      setAction(actionResponse)
      setLoading(false)
    })
  }, [action.formDescription])

  return (
    <>
      <AlertPopup message={alertMessage} isOpen={Boolean(alertMessage)} openStateHandler={setAlertMessage} />
      {loading ? (
        <></>
      ) : (
        <FormInputComponent
          inputCallback={updateActionStatus}
          formLabel={action.formLabel}
          actionName={action.type}
          description={action.formDescription}
          fields={action.fields}
          approvalRequired={action.approvalRequired}
        />
      )}
    </>
  )
}
