import React from "react"
import { Card } from "@blueprintjs/core"
import { Container, Row, Col } from "react-grid-system"

const ActionListHeader = () => {
  const styles = {
    card: {
      background: "#F4F5F4",
    },
    text: {
      fontFamily: "Raleway",
      fontWeight: "bold" as "bold",
    },
  }

  return (
    <Card style={styles.card}>
      <Container>
        <Row>
          <Col md={3}>
            <p style={styles.text}> Agent </p>
          </Col>
          <Col md={1}>
            <p style={styles.text}> Action </p>
          </Col>
          <Col md={3}>
            <p style={styles.text}> Agent Field(s) Passed </p>
          </Col>
          <Col md={3}>
            <p style={styles.text}> Admin Notes </p>
          </Col>
          <Col md={2}>
            <p style={styles.text}> Status </p>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

export default ActionListHeader
