import React, { FunctionComponent } from "react"
import Layout from "../../shared/Layout/layout"
import { RouteProps } from "../../utils/types"
import RedashUserIFrame from "../../shared/RedashUserIFrame/redashUserIFrame"

export interface RebateActionPageProps extends RouteProps {
  searchTerm?: string,
}

const RedashUserSearchContent: FunctionComponent<RebateActionPageProps> = (props: RebateActionPageProps) => {
  const redashPath = `https://app.redash.io/northone/dashboard/users_40?p_keyword=${props.searchTerm}`
  return (
    <Layout>
      <RedashUserIFrame redashPath={redashPath} />
    </Layout>
  )
}

export default RedashUserSearchContent
