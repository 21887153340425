import { FormFields } from "../shared/FormInput/formInput.component"
import validator from "validator"

import { FormInputField } from "../../../types"

export enum ValidatorStatuses {
  "VALID",
  "INVALID",
}

type ValidatorResponse = {
  status: ValidatorStatuses
  message: string
}

export const validateBaseActionCreateRequestBody = (
  formInput: FormFields,
  requiredFormInputKeys: FormInputField["requestInputKey"][],
): ValidatorResponse => {
  const invalidFormInputKeys: [FormInputField["requestInputKey"], string | number][] = []

  Object.entries(formInput).forEach(([key, value]) => {
    let isValid = true
    const formInputKey = key as FormInputField["requestInputKey"]

    if (formInputKey === "businessId") {
      isValid = validator.isUUID(value as string)
    } else if (key === "treasuryPrimeAccountId") {
      isValid = validator.isAscii(value as string) && (value as string).startsWith("acct_")
    } else if (key === "userId") {
      isValid = validator.isUUID(value as string)
    } else if (formInputKey === "amount") {
      isValid = validator.isFloat(value as string)
    } else if (formInputKey === "cardNumber") {
      isValid = String(value).length === 4 && validator.isNumeric(String(value))
    } else if (requiredFormInputKeys.includes(formInputKey)) {
      isValid = Boolean(value)
    }

    if (!isValid) {
      invalidFormInputKeys.push([formInputKey, value])
    }
  })

  const isValidForm = invalidFormInputKeys.length < 1 ? true : false
  if (isValidForm) {
    return {
      status: ValidatorStatuses.VALID,
      message: "",
    }
  } else {
    const isValidFormMessage = invalidFormInputKeys
      .map(([key, value]) =>
        Boolean(value)
          ? `${key} ${value} is not a valid input value. Please update.`
          : `${key} is a required input. ${key} must not be empty.`,
      )
      .join(" ")
    return {
      status: ValidatorStatuses.INVALID,
      message: isValidFormMessage,
    }
  }
}
