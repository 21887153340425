import React, { FunctionComponent } from "react"
import Avatar from "../Avatar/avatar"
import { Card, Callout } from "@blueprintjs/core"
import { Container, Row, Col } from "react-grid-system"
import { IClientAction, ActionStatusEnum } from "../../../../types"
import AdminDecisionPopover from "../AdminDecisionPopover/adminDecisionPopovercomponent"

export type AdminActionListProps = IClientAction

const AdminActionCard: FunctionComponent<AdminActionListProps> = ({
  id,
  createdBy,
  createdAt,
  type,
  requestInput,
  adminNote,
  confirmedAt,
  confirmedBy,
  status,
}) => {
  const styles = {
    callout: {
      backgroundColor: "#6495ed",
      color: "#FFF",
      fontFamily: "Quicksand",
    },
    buttons: {
      marginTop: "15px",
    },
  }

  const formatRequestInput = (JSONString: any): JSX.Element[] => {
    const newStringArray: JSX.Element[] = Object.keys(JSONString).map((key, index) => (
      <p key={index}>
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{`${key}: `}</span>
        {`${JSONString[key]}`}
      </p>
    ))

    return newStringArray
  }

  const getDecisionButtons = () => (
    <Container style={styles.buttons} fluid={true}>
      <Row>
        <Col offset={{ md: 8 }}>
          <AdminDecisionPopover
            createdAt={createdAt}
            createdByName={`${createdBy.firstName} ${createdBy.lastName}`}
            type={type}
            actionId={id}
          />
        </Col>
      </Row>
    </Container>
  )

  // This function leverages the grid system of React-grid-system
  // By setting one div to 3 and one to 12, we can gurantee a new row will be created due to
  // overflow (grid system is 12)
  const confirmedByNotNullComponent = () => (
    <Row>
      <Col md={3}>
        <Avatar imageSrc={confirmedBy.picture} />
      </Col>
      <Col md={12}>
        <p>
          <span style={{ fontWeight: "bold" }}>
            {confirmedBy.firstName} {confirmedBy.lastName}
          </span>
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}> Notes </span> {adminNote}
          <br />
          <span style={{ fontWeight: "bold" }}> Confirmed At: </span>
          {confirmedAt}
        </p>
      </Col>
    </Row>
  )

  return (
    <Card>
      <Container fluid={true}>
        <Row>
          <Col md={1}>
            <Avatar imageSrc={createdBy.picture} />
          </Col>
          <Col md={2}>
            <p>
              {createdBy.firstName} {createdBy.lastName}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Created at: </span> {createdAt}
            </p>
          </Col>
          <Col md={1}>
            <p style={{ fontWeight: "bold", textTransform: 'capitalize' }}>{type.replace("-", " ")}</p>
          </Col>
          <Col md={3}>
            <Callout style={styles.callout}>{formatRequestInput(requestInput)}</Callout>
          </Col>
          <Col md={3}>
            {confirmedBy !== null ? <Callout style={styles.callout}>{confirmedByNotNullComponent()}</Callout> : <></>}
          </Col>
          <Col md={2}>
            <Callout style={styles.callout}>
              <p style={{ fontWeight: "bold" }}>{status}</p>
            </Callout>
          </Col>
        </Row>
      </Container>

      {status === ActionStatusEnum.PENDING ? getDecisionButtons() : <> </>}
    </Card>
  )
}

export default AdminActionCard
