import React from "react"
import ActionCard from "../ActionCard/actionCard"
import ActionListHeader from "../ActionListHeader/actionListHeader"
import { ButtonGroup } from "@blueprintjs/core"
import { IClientAction } from "../../../../types/index"
import { userTypeEnum } from "../../shared/ActionList/actionList.container"
import AdminActionCard from "../AdminActionCard/adminActionCard.component"
import moment from "moment"
import { Container } from "react-grid-system"

type ActionListComponentProps = {
  actions: IClientAction[]
  paginationButtons: JSX.Element[]
  listType: userTypeEnum
}

const ActionListComponent = ({ actions, paginationButtons, listType }: ActionListComponentProps) => {
  const styles = {
    list: {
      maxWidth: "100%",
      marginRight: "12px",
      maxHeight: "500px",
      overflow: "scroll",
      marginTop: "5px",
    },
    button: {
      marginLeft: "15px",
      marginBottom: "15px",
      fontWeight: "bold" as "bold",
      fontFamily: "Raleway",
    },
  }

  const formatTime = (time: string): string => {
    const date = moment(time).format("MMMM Do YYYY, h:mm:ss a")
    return date
  }

  return (
    <Container fluid={true}>
      <div>
        <ButtonGroup>{paginationButtons}</ButtonGroup>
      </div>
      <div>
        <ActionListHeader />
        <ul style={styles.list}>
          {listType === userTypeEnum.ADMIN
            ? actions.map((item: IClientAction, key: number) => (
                <AdminActionCard
                  key={key}
                  id={item.id}
                  meta={item.meta}
                  createdAt={formatTime(item.createdAt)}
                  confirmedAt={formatTime(item.confirmedAt)}
                  adminNote={item.adminNote}
                  createdBy={item.createdBy}
                  type={item.type}
                  status={item.status}
                  requestInput={item.requestInput}
                  confirmedBy={item.confirmedBy}
                />
              ))
            : actions.map((item: IClientAction, key: number) => (
                <ActionCard
                  key={key}
                  id={item.id}
                  meta={item.meta}
                  createdAt={formatTime(item.createdAt)}
                  confirmedAt={formatTime(item.confirmedAt)}
                  adminNote={item.adminNote}
                  createdBy={item.createdBy}
                  type={item.type}
                  status={item.status}
                  requestInput={item.requestInput}
                  confirmedBy={item.confirmedBy}
                />
              ))}
        </ul>
      </div>
    </Container>
  )
}

export default ActionListComponent
