import React, { useState, FunctionComponent } from "react"
import BaseAdminPopover from "../BaseAdminPopover/baseAdminPopover"
import { ActionStatusEnum } from "../../../../types"
import CSPortal from "../../data-sources/csRestApi"
import { AdminPopoverProps } from "../AdminDecisionPopover/adminDecisionPopovercomponent"
import { Spinner } from "@blueprintjs/core"

const DeclineAdminPopover: FunctionComponent<AdminPopoverProps> = ({
  actionId,
  type,
  createdAt,
  createdByName,
  isOpen,
  isOpenStateHandler,
}) => {
  const [declineNotes, setDeclineNotes] = useState("")
  const [loading, setLoading] = useState(false)

  const declineHandler = async () => {
    setLoading(true)
    const response = await CSPortal.makeDecisionOnAction({
      status: ActionStatusEnum.REJECTED,
      adminNote: declineNotes,
      actionId,
    })
    if (response.status === ActionStatusEnum.REJECTED) {
      console.log("Success")
    }

    // This is to refresh to re-fetch data, will need global state eventually
    // to implement but this works for now
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  return (
    <BaseAdminPopover
      buttonIntent="danger"
      createdAt={createdAt}
      createdByName={createdByName}
      type={type}
      buttonText={
        !loading ? (
          "Decline"
        ) : (
          <>
            {" "}
            <Spinner intent="primary" /> Decline{" "}
          </>
        )
      }
      popoverTitle={"Decline Action"}
      notes={declineNotes}
      notesStateHandler={setDeclineNotes}
      confirmHandler={declineHandler}
      isOpen={isOpen}
      isOpenStateHandler={isOpenStateHandler}
      helperText={"Reason for declining action. "}
    />
  )
}

export default DeclineAdminPopover
