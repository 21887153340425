import React, { useState, FunctionComponent } from "react"
import { ActionStatusEnum } from "../../../../types"
import CSPortal from "../../data-sources/csRestApi"
import BaseAdminPopover from "../BaseAdminPopover/baseAdminPopover"
import { AdminPopoverProps } from "../AdminDecisionPopover/adminDecisionPopovercomponent"
import { Spinner } from "@blueprintjs/core"

const AcceptAdminPopover: FunctionComponent<AdminPopoverProps> = ({
  actionId,
  type,
  createdAt,
  createdByName,
  isOpen,
  isOpenStateHandler,
}) => {
  const [acceptNotes, setAcceptNotes] = useState("")
  const [loading, setLoading] = useState(false)

  const approvalHandler = async () => {
    setLoading(true)
    const response = await CSPortal.makeDecisionOnAction({
      status: ActionStatusEnum.APPROVED,
      adminNote: acceptNotes,
      actionId,
    })
    if (response.status === ActionStatusEnum.APPROVED) {
      console.log("Success")
    }

    // This is to refresh to re-fetch data, will need global state eventually
    // to implement but this works for now
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  return (
    <BaseAdminPopover
      buttonIntent={"success"}
      createdAt={createdAt}
      createdByName={createdByName}
      type={type}
      buttonText={
        !loading ? (
          "Accept"
        ) : (
          <>
            {" "}
            <Spinner intent="primary" size={Spinner.SIZE_SMALL} /> Accept{" "}
          </>
        )
      }
      popoverTitle={"Accept Action"}
      confirmHandler={approvalHandler}
      isOpen={isOpen}
      isOpenStateHandler={isOpenStateHandler}
      notes={acceptNotes}
      notesStateHandler={setAcceptNotes}
      helperText={"Reason for accepting action. "}
    />
  )
}

export default AcceptAdminPopover
