import React from "react"
import ResourcePage from "../components/ResourcesPage/resourcePage"
import ActionPage from "../components/ActionPage/actionPage"
import RedashUserSearchPage from "../components/RedashUserSearchPage/redashUserSearchPage"
import RedashTransactionSearchPage from "../components/RedashTransactionSearchPage/redashTransactionSearchPage"
import UserSearchPage from "../components/UserSearchPage/userSearchPage"
import NoPermissionsPage from "../components/NoPermissionsPage/noPermissionsPage"
import NotFoundPage from "../pages/404"
import ActivityPage from "../components/ActivityPage/activityPage"
import RedirectAuthPage from "../components/RedirectAuthPage/redirectAuthPage"
import { Router, Location } from "@reach/router"
import { withAuthenticator } from "aws-amplify-react"
import { Auth } from "aws-amplify"
import { authConfig, authenticatorOptions } from "../utils/authOptions"
import { ActionTypeEnum } from "../../../types"

Auth.configure(authConfig)

const App = () => {
  return (
    <Location>
      {({ location }: any) => (
        <Router location={location}>
          <NotFoundPage default />
          <RedirectAuthPage path="/" />
          <NoPermissionsPage path="/app" />
          <ResourcePage path="/app/resources" />
          <RedashUserSearchPage path="/app/resources/redash-user-search/:searchTerm" />
          <RedashTransactionSearchPage path="/app/resources/redash-transaction-search" />
          <UserSearchPage path="/app/users/search" />
          <ActivityPage path="/app/activity" />
          <ActionPage actionName={ActionTypeEnum.REBATE} path={`/app/actions/${ActionTypeEnum.REBATE}`} />
          <ActionPage actionName={ActionTypeEnum.DISABLE_USER} path={`/app/actions/${ActionTypeEnum.DISABLE_USER}`} />
          <ActionPage actionName={ActionTypeEnum.CHANGE_CARD_NUMBER} path={`/app/actions/${ActionTypeEnum.CHANGE_CARD_NUMBER}`} />
        </Router>
      )}
    </Location>
  )
}
export default withAuthenticator(App, false, [], {}, authenticatorOptions.theme)
