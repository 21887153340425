import React, { FunctionComponent, useState } from "react"
import Layout from "../../shared/Layout/layout"
import { RouteProps } from "../../utils/types"
import { FormInput } from "../../shared/FormInput"
import { ActionList } from "../../shared/ActionList"
import { ActionTypeEnum } from "../../../../types"
import { Toggler } from "../../shared/Toggler"
import { userTypeEnum } from "../../shared/ActionList/actionList.container"
import { Container, Row, Col } from "react-grid-system"
import RedashUserIFrame from "../../shared/RedashUserIFrame/redashUserIFrame"

export interface ActionPageProps extends RouteProps {
  actionName: ActionTypeEnum
}

const ActionPage: FunctionComponent<ActionPageProps> = ({ actionName }) => {
  const styles = {
    container: {
      marginTop: "5%",
    },
  }

  const [open, setOpen] = useState(true)
  const redashPath = `https://app.redash.io/northone/dashboard/cs_usersearch`

  return (
    <Layout>
      <Container style={styles.container}>
        <Row>
          <Col md={4}>
            <FormInput actionName={actionName} />
          </Col>
          <Col md={8}>
            <RedashUserIFrame width={"100%"} height={"100%"} redashPath={redashPath} />
          </Col>
        </Row>
      </Container>
      <Toggler isFluid={true} open={open} setOpen={setOpen} label="action logs">
        <div style={{ marginTop: "15px" }}>
          <ActionList listType={userTypeEnum.AGENT} type={actionName} />
        </div>
      </Toggler>
    </Layout>
  )
}

export default ActionPage
