import { FormFields } from "../shared/FormInput/formInput.component"

export const transformBaseRequestFromFormInput = (formInput: FormFields): FormFields => {
  const inputData = {} as FormFields
  /* tslint:disable:array-type*/
  const formInputKeys = Object.keys(formInput) as Array<keyof FormFields>

  formInputKeys.forEach((key) => {
    inputData[key] = formInput[key]
    if (key === "amount") {
      inputData[key] = parseFloat(formInput[key] as string)
    }
  })
  return inputData
}
