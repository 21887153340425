import React, { FunctionComponent } from "react"
import { Container, Row, Col } from "react-grid-system"
import { FormGroup, Label, TextArea, Intent, ButtonGroup, Button, Dialog } from "@blueprintjs/core"

type IProps = {
  confirmHandler(): void
  type: string
  createdAt: string
  createdByName: string
  isOpen: boolean
  isOpenStateHandler(state: boolean): void
  buttonText: string | JSX.Element
  buttonIntent: Intent
  popoverTitle: string
  notes: string
  notesStateHandler: React.Dispatch<React.SetStateAction<string>>
  helperText: string
}

const BaseAdminPopover: FunctionComponent<IProps> = ({
  createdAt,
  createdByName,
  type,
  isOpen,
  isOpenStateHandler,
  confirmHandler,
  buttonText,
  buttonIntent,
  popoverTitle,
  notes,
  notesStateHandler,
  helperText,
}) => {
  const styles = {
    dialog: {
      padding: "15px",
    },
  }

  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      onClose={() => {
        isOpenStateHandler(!isOpen)
      }}
    >
      <Container style={styles.dialog}>
        <Row>
          <Col>
            <h1> {popoverTitle} </h1>

            <p>
              <span style={{ fontWeight: "bold" as "bold" }}> Created By: </span> {createdByName}
              <br />
              <span style={{ fontWeight: "bold" as "bold" }}> Created at</span> {createdAt}
              <br />
              <span style={{ fontWeight: "bold" as "bold" }}> Action Type</span> {type}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup helperText={helperText}>
              <Label>
                Admin Notes
                <TextArea
                  large={true}
                  growVertically={true}
                  intent={Intent.PRIMARY}
                  placeholder="Notes...."
                  value={notes}
                  onChange={(evt: any) => notesStateHandler(evt.target.value)}
                />
              </Label>
            </FormGroup>

            <ButtonGroup>
              <Button intent={buttonIntent} onClick={() => confirmHandler()}>
                {buttonText}
              </Button>
              <Button intent="warning" onClick={() => isOpenStateHandler(!isOpen)}>
                Cancel
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
    </Dialog>
  )
}

export default BaseAdminPopover
