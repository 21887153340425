import React, { FunctionComponent, useState } from "react"
import Layout from "../../shared/Layout/layout"
import { RouteProps } from "../../utils/types"
import { InputGroup, Button, Callout } from "@blueprintjs/core"
import { navigate } from "gatsby"

// tslint:disable-next-line: no-empty-interface
export interface UserSearchPageProps extends RouteProps {}

const UserSearchPage: FunctionComponent<UserSearchPageProps> = () => {
  const [keyword, setKeyword] = useState("")

  const styles = {
    searchBar: {
      margin: "0 auto",
      width: "80%",
      paddingTop: "25%",
    },
    formatCenter: {
      width: "80%",
      margin: "0 auto",
      marginTop: "25%",
      marginBottom: "-20%",
    },
  }

  return (
    <Layout>
      <Callout style={styles.formatCenter} title="Search for a User">
        Please use the search bar below to search for a user. This search uses Redash data.
      </Callout>

      <div style={styles.searchBar}>
        <InputGroup
          large={true}
          placeholder={"Search Users" as string}
          value={keyword}
          onChange={(event: any) => setKeyword(event.target.value)}
          type="text"
          name="keyword"
        />

        <Button onClick={() => navigate(`/app/resources/redash-user-search/${keyword}`)}> Search </Button>
      </div>
    </Layout>
  )
}

export default UserSearchPage
